import { is } from 'ramda'
import { USER_ROLES } from '@/lib/constants/user-roles'

type Params = {
  onSuccess?: () => void
  onError?: (error?: { response: { status: number; data: { message: string } } }) => void
}

export function useOauthServices({ onSuccess, onError }: Params = {}) {
  const route = useRoute()
  const router = useRouter()
  const { redirectTo } = useRedirect()
  const { $analytics } = useNuxtApp()
  const hasError = ref(false)
  const config = useRuntimeConfig()
  const { createWithAlternateService, userRole, userHasCompanies } = useUser()

  /**
   * Initiate the oauth authentication and sharing protocol window and when
   * finished get the user object and log in with the token.
   * @param {String} serviceEndpoint - the API endpoint at which the logic containing the handling
   * for the service is (e.g. for LinkedIn, it would be /auth/linkedin/web)
   */
  function connectWithAlternateService(serviceEndpoint: string, loginOrSignup: 'login' | 'signup') {
    const oauthWindow = window.open(
      `${config.public.API_ENDPOINT}${serviceEndpoint}?next=/v2/auth/?close=${window.location}`,
      'oauthWindow',
    )

    window.onmessage = async ({ data }) => {
      const isInternalAdminSsoError = oauthWindow && typeof data === 'object' && data.message
      const isToken = oauthWindow && typeof data === 'string'

      if (isInternalAdminSsoError) {
        const error = {
          response: {
            status: 403,
            data: {
              message: data.message,
            },
          },
        }

        !onError ? oauthError() : onError(error)
        window.onmessage = null
      }

      if (isToken) {
        oauthWindow.close()

        try {
          await createWithAlternateService()

          !onSuccess ? oauthSuccess() : onSuccess()
        } catch (error: any) {
          const errorEvent = loginOrSignup === 'login' ? 'loginError' : 'signUpSubmittedError'

          $analytics.track(errorEvent, {
            errorMessage: error?.response?.data?.message ? error.response.data.message : error,
          })

          !onError ? oauthError() : onError()
        } finally {
          window.onmessage = null
        }
      }
    }
  }

  function oauthSuccess() {
    const currentPath = route.path
    const redirectPath = route.query.redirectedFrom as string
    const shouldRedirect = is(String, redirectPath) && !redirectPath.includes('password-reset') && currentPath !== redirectPath
    const shouldRedirectToEditor =
      !redirectPath?.includes('password-reset') && userRole.value && (userRole.value === USER_ROLES.superAdmin || userHasCompanies)

    if (shouldRedirect) {
      delete route.query.redirectedFrom
      redirectTo({ path: redirectPath, query: route.query })
      return
    }

    if (shouldRedirectToEditor) {
      redirectTo({ path: `${window.location.origin}/admin` })
      return
    }

    router.push({ name: 'user-profile' })
  }

  function oauthError() {
    hasError.value = true

    setTimeout(() => {
      hasError.value = false
    }, 10000)
  }

  return {
    hasError,
    oauthError,
    oauthSuccess,
    connectWithAlternateService,
  }
}
