export interface UseRedirect {
  redirectTo: ({ path, params, query }: { path: string; params?: {}; query?: {} }) => void
  delayedRedirectTo: ({ path, params, query, time }: { path: string; params?: {}; query?: {}; time: number }) => void
}

export function useRedirect(): UseRedirect {
  const $router = useRouter()
  const redirectTo = ({ path, params, query }: { path: string; params?: {}; query?: {} }) => {
    if (isExternalUrl(path)) {
      const httpUrlFromRedirectPathString = new URL(path, location.origin)
      window.location.href = httpUrlFromRedirectPathString.href
    } else {
      let routerObject: { path: string; query?: {}; params?: {} } = { path }
      if (params) {
        routerObject.params = params
      }

      if (query) {
        routerObject.query = query
      }

      const location = $router.resolve(routerObject)
      if (location.matched.length) {
        $router.push(routerObject)
        return
      }

      navigateTo(location.href)
    }
  }

  const delayedRedirectTo = ({ path, params = {}, query = {}, time }: { path: string; params?: {}; query?: {}; time: number }) => {
    setTimeout(() => {
      redirectTo({ path, params, query })
    }, time)
  }

  const isExternalUrl = (url: string) => {
    return /^https?:\/\//.test(url)
  }

  return {
    redirectTo,
    delayedRedirectTo,
  }
}
